.terminal-popup {
  position: fixed;
  bottom: 2vh;
  z-index: 10;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  transform: translate(30vh, 30vh);
  opacity: 0;
}

.terminal-popup.init {
  transition: all 200ms ease-out;
  transform: translate(0);
  opacity: 1;
}

.terminal-popup.hide {
  transition: all 200ms ease-out;
  opacity: 0;
  transform: translate(30vh, 30vh);
}

.terminal-popup.expand {
  transition: all 200ms ease-out;
  width: 100vw;
  top: 0;
}

.title {
  color: rgba(139, 180, 216, 0.94);
}

.sidebar {
  background-color: rgb(23, 42, 69);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  color: rgb(136, 146, 176);
  opacity: 1;
}

.sidebar-link {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: rgba(139, 180, 216, 0.94);
}

.header-name {
  font-weight: 600;
  color: rgb(230, 241, 255);
}

.column-header {
  border-bottom: 2px rgb(45, 57, 82) solid;
  margin-bottom: 1rem;
}

.column-header > h1 {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  color: white;
}

.icon,
.sidebar-link {
  transition-duration: 250ms;
}

.icon:hover {
  color: white;
}

.terminal-bar {
  background-color: #eae8e9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  position: relative;
}

.terminal-bar.expand {
  border-radius: 0;
}

.terminal-border {
  border-top: 2px dashed #b7c5d2;
  margin-right: 8px;
}

.terminal-help-border {
  border: 2px dashed white;
  width: 100%;
  color: #b7c5d2;
  padding: 0 10px;
}

.dark-mode {
  background-color: #232323;
}

.dark-mode-text {
  color: #bdb9bf !important;
}

.icon-btn {
  border-radius: 50%;
  margin-top: 7px;
  height: 15px !important;
  width: 15px !important;
  margin-bottom: 0.5rem;
  z-index: 1;
  border: none;
  cursor: default !important;
  padding: 0;
}

.icon-btn:focus {
  outline: none;
}

.terminal-bar > button.icon-btn:first-child {
  margin-left: 0.6rem;
}

.terminal-bar > button.icon-btn:not(:first-child) {
  margin-left: 0.5rem;
}

.terminal-bar > button.icon-btn:last-child {
  margin-right: 0.6rem;
}

.close-button {
  background-color: #ff605c;
}

.maximize-button {
  background-color: #00ca4e;
}

.minimize-button {
  background-color: #ffbd44;
}

.close-button:hover,
.maximize-button:hover,
.minimize-button:hover {
  opacity: 0.9;
}

.close-button:active,
.maximize-button:active,
.minimize-button:active {
  opacity: 0.8;
}

.terminal-window {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 55vh;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.terminal-window.expand {
  transition: all 200ms ease-out;
  width: 100vw;
  height: 100vh;
  border-radius: 0;
}

.primary-bg {
  background-color: rgb(23, 42, 69);
}

.shadow {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.terminal-bar-text {
  position: absolute;
  margin-top: 3px;
  color: #383838;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.has-equal-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.terminal-output {
  overflow-y: hidden;
  overflow: auto;
  z-index: 100;
}

.terminal-output.expand {
  padding-right: 15px;
}

.column-child {
  flex: 1;
}

.terminal-line {
  position: relative;
  font-family: "Anonymous Pro", monospace;
  font-size: 0.9rem;
  color: #b7c5d2;
}

.directory {
  color: #75e1e7;
  font-weight: 500;
}

.success {
  color: #8dd39e;
}

.code,
.error {
  color: #d7566a;
}

.terminal-keyboard {
  opacity: 0;
  filter: alpha(opacity=0);
}

::-webkit-scrollbar {
  display: none;
}

.cursor {
  position: relative;
}
.cursor i {
  position: absolute;
  width: 1px;
  height: 80%;
  background-color: gray;
  top: 10%;
  animation-name: blink;
  animation-duration: 1100ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.cursor input:focus + i {
  display: none;
}

.user-input {
  font-family: "Anonymous Pro", monospace;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media screen and (max-width: 1200px) {
  .terminal-popup {
    width: 70vw;
  }

  .terminal-window.expand {
    margin-top: -1px;
    min-height: -webkit-fill-available;
  }

  .terminal-line.expand {
    padding-bottom: 4vh;
  }
}

@media screen and (max-width: 768px) and (max-height: 1000px) {
  .terminal-popup {
    width: 90vw;
  }

  .terminal-window {
    height: 50vh;
  }
}
