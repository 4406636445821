.header-page {
  width: 100%;
  height: 75vh;
  position: relative;
  background: #fff;
  margin: 0;
  margin-top: -5px;
  padding-top: 20px;
  min-height: 600px;
}
.header-content {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
}
.background-circle {
  background: linear-gradient(135deg, rgb(0 126 255) 60%, rgb(122 0 255) 100%);
  position: absolute;
  bottom: 0;
  left: -150vw;
  border-radius: 50%;
  width: 400vw;
  height: 400vw;
  overflow: hidden;
}
.page-title {
  color: #fff;
  text-align: center;
  padding-top: 25vh;
}
.page-title h1 {
  font-size: 3.5em;
  font-weight: 900;
}
.page-title p {
  font-size: 1.5em;
  font-weight: 350;
}

.particles-js-canvas-el {
  position: absolute;
  width: "100%";
  height: "auto";
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.social-icon {
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.15);
}
.social-list li:not(:last-child) {
  margin-right: 3%;
}
@media (max-width: 1024px) {
  .header-page {
    padding-top: 15vh;
    height: 105vh;
  }
}
@media (max-width: 992px) {
  .page-title {
    float: initial;
    margin-left: 0;
  }
  .page-title h1,
  .page-title p {
    text-align: center;
    margin-left: 0;
  }
}
@media only screen and (max-width: 780px) {
  .page-title {
    float: initial;
    margin-left: 0;
    padding-top: 15vh;
  }

  .header-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .page-title h1 {
    font-size: 3em;
  }
  .social-list li:not(:last-child) {
    margin-right: 7%;
  }
  .social-list li:not(:last-child):not(:first-child) {
    margin-right: 7%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 350px) {
  .page-title h1 {
    font-size: 2.5em;
  }
}

.animate-reveal {
  opacity: 0;
  -webkit-animation: reveal 500ms cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
  -moz-animation: reveal 500ms ease-in 1 normal forwards;
  -o-animation: reveal 500ms cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
  animation: reveal 500ms cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
}

.animate-first {
  -webkit-animation-delay: 400ms;
  -moz-animation-delay: 400ms;
  -o-animation-delay: 400ms;
  animation-delay: 400ms;
}

.animate-second {
  -webkit-animation-delay: 600ms;
  -moz-animation-delay: 600ms;
  -o-animation-delay: 600ms;
  animation-delay: 600ms;
}

.animate-third {
  -webkit-animation-delay: 800ms;
  -moz-animation-delay: 800ms;
  -o-animation-delay: 800ms;
  animation-delay: 800ms;
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
