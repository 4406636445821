.pdf-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.button-container {
  text-align: center;
}

.button-container {
  margin-top: -35px;
  position: relative;
}
.pdf-btn {
  margin-left: 5px;
  border: none;
  background-color: inherit;
}

.pdf-btn:hover:enabled {
  color: #1178ff;
}

.react-pdf__Page__textContent {
  max-width: 90vw;
  max-height: 90vh;
}

.react-pdf__Page__canvas {
  max-width: 90vw;
  max-height: 90vh;
}
