html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}
img {
  width: 100%;
}
*,
h1,
p,
a {
  font-family: Roboto, sans-serif;
}

h4 {
  font-size: 1rem;
}

h6 {
  margin-bottom: 2px;
}

.section-header {
  font-size: 3em;
  font-weight: 900;
}

.section-subheader {
  font-size: 2.5em;
  font-weight: 600;
}

.section-information {
  font-size: 2em;
  margin: 10px 20px 10px;
}

.component-section {
  margin: 50px 0;
}

.pointer {
  cursor: pointer;
}

.modal-btn {
  border: none;
  background-color: inherit;
  font-size: 20px;
  z-index: 1000;
  position: absolute;
}

.modal-btn-right {
  right: 0;
}

.modal-btn:hover {
  color: #1178ff;
}

.floating-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #1178ffe0;
  transform: translateY(70px);
  opacity: 0;
}

.floating-icon.init {
  transition: all 250ms ease-out;
  transform: translateY(0);
  opacity: 0.9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13), 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.floating-icon.is-init {
  transform: translateY(0);
  opacity: 0.9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13), 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.floating-icon.init:hover,
.floating-icon.is-init:hover {
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.24);
  opacity: 1;
  background-color: #1178ff;
  transition: 300ms;
}

.floating-icon.mobile {
  background-color: #fff;
  color: #1178ffe0;
}

.floating-icon.mobile-scrolled {
  background-color: #1178ffe0;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .floating-icon {
    right: 6px;
  }
}

.swipe {
  max-width: 400px;
}

.lazy-loader > div {
  margin: 0 auto;
  margin-top: 20px;
  display: block;
}
