.nav {
  width: 100%;
  height: 65px;
  position: fixed;
  line-height: 65px;
  text-align: center;
  z-index: 10;
}

.nav div.logo {
  float: left;
  width: auto;
  height: auto;
  padding-left: 3rem;
}

.nav div.main_list {
  height: 65px;
  float: right;
  opacity: 1;
}

.nav div.main_list ul {
  width: 100%;
  height: 65px;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav div.main_list ul li {
  width: auto;
  height: 65px;
  padding: 0;
  padding-right: 3rem;
}

.nav div.main_list ul li p {
  text-decoration: none;
  color: #fff;
  line-height: 65px;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.nav div.main_list ul li p:hover {
  color: #00e676;
}

.navigation-btn {
  display: none;
  transition: all 0.5s;
}

.nav {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.logo-img {
  max-width: 80px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navigation-btn {
    display: block;
  }
  .nav div.logo {
    margin-left: -30px;
  }
  .logo-img {
    max-width: 70px;
  }
  .nav div.main_list {
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .nav div.show_list {
    height: auto;
    opacity: 1;
    transition: opacity 400ms;
  }
  .nav div.main_list ul {
    flex-direction: column;
    width: 100%;
    height: 30%;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background-position: center top;
    background-color: #232323;
    border-radius: 10px;
  }
  .nav div.main_list ul li {
    width: 100%;
    text-align: right;
    padding-right: 0;
  }
  .nav div.main_list ul li p {
    text-align: center;
    width: 100%;
    font-size: 1.75rem;
  }
  .nav div.media_button {
    display: block;
  }
}

.navigation-btn {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 30px;
  top: 40px;
  bottom: 0;
}

.navigation-btn i {
  background-color: #fff;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
}

.affix {
  padding: 0;
  background-color: #232323;
}

.affix-border {
  border-radius: 0 0 10px 10px !important;
  transition: 0.8s;
}

.nav-btn-sm {
  padding: 3px 0;
  top: 0;
}

/* Animations */

.navigation-btn i:nth-child(1) {
  -webkit-animation: outT 0.5s backwards;
  animation: outT 0.5s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navigation-btn i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.5s backwards;
  animation: outM 0.5s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navigation-btn i:nth-child(3) {
  -webkit-animation: outBtm 0.5s backwards;
  animation: outBtm 0.5s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navigation-btn.active i:nth-child(1) {
  -webkit-animation: inT 0.5s forwards;
  animation: inT 0.5s forwards;
}

.navigation-btn.active i:nth-child(2) {
  -webkit-animation: inM 0.5s forwards;
  animation: inM 0.5s forwards;
}

.navigation-btn.active i:nth-child(3) {
  -webkit-animation: inBtm 0.5s forwards;
  animation: inBtm 0.5s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
